import type { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  createContext as _createContext,
  useContext as _useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export function createStateContext<Value>(
  displayName: string,
  defaultValue: Value,
) {
  const Context = _createContext<[Value, Dispatch<SetStateAction<Value>>]>([
    defaultValue,
    () => {},
  ]);

  function Provider({
    children,
    value = defaultValue,
  }: {
    children: ReactNode;
    value?: Value;
  }) {
    const [state, setState] = useState<Value>(value);

    useEffect(() => setState(value), [value]);

    return (
      <Context.Provider value={useMemo(() => [state, setState], [state])}>
        {children}
      </Context.Provider>
    );
  }

  Provider.displayName = `${displayName}.Provider`;

  function useContext<T = undefined>() {
    return _useContext(Context) as [
      T extends undefined ? Value : T,
      Dispatch<SetStateAction<T extends undefined ? Value : T>>,
    ];
  }

  return {
    defaultValue,
    Provider,
    useContext,
  } as const;
}
