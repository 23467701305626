import type { User } from '@iheartradio/web.config';
import { createStateContext } from '@iheartradio/web.remix-shared/react/create-state-context.js';
import type { ReactNode } from 'react';

export interface UserProviderProps {
  children?: ReactNode;
  value?: User | undefined;
}

const { Provider: UserContextProvider, useContext } =
  createStateContext<User | null>('User', null);

function useUser() {
  const [user] = useContext();
  return user ?? ({} as User);
}

export { UserContextProvider, useUser };
