import type { StyleRule } from '@vanilla-extract/css';

import type { ThemeColorOrAnyColor } from './themes/default/theme-tokens.js';

export const breakpointSizes = {
  tablet: 768,
  desktop: 1024,

  xsmall: 320,
  small: 421,
  shmedium: 560,
  medium: 769,
  large: 1025,
  xlarge: 1367,
} as const;

export function containerMediaBreakpoint<N extends number>(
  n: N,
): `(min-width: ${N}px)` {
  return `(min-width: ${n}px)`;
}

export function mediaBreakpoint<N extends number>(
  n: N,
): `screen and (min-width: ${N}px)` {
  return `screen and (min-width: ${n}px)`;
}

export function maxMediaBreakpoint<N extends number>(
  n: N,
): `screen and (max-width: ${N}px)` {
  return `screen and (max-width: ${n}px)`;
}

export function prefersReducedMotion(styles: StyleRule) {
  return {
    '(prefers-reduced-motion)': styles,
  };
}

export const breakpoints = {
  mobile: mediaBreakpoint(0),
  tablet: mediaBreakpoint(breakpointSizes.tablet),
  desktop: mediaBreakpoint(breakpointSizes.desktop),

  xsmall: mediaBreakpoint(breakpointSizes.xsmall),
  small: mediaBreakpoint(breakpointSizes.small),
  shmedium: mediaBreakpoint(breakpointSizes.shmedium),
  medium: mediaBreakpoint(breakpointSizes.medium),
  large: mediaBreakpoint(breakpointSizes.large),
  xlarge: mediaBreakpoint(breakpointSizes.xlarge),
} as const;

export const maxBreakpoints = {
  tablet: maxMediaBreakpoint(breakpointSizes.tablet),
  desktop: maxMediaBreakpoint(breakpointSizes.desktop),

  xsmall: maxMediaBreakpoint(breakpointSizes.xsmall),
  small: maxMediaBreakpoint(breakpointSizes.small),
  shmedium: maxMediaBreakpoint(breakpointSizes.shmedium),
  medium: maxMediaBreakpoint(breakpointSizes.medium),
  large: maxMediaBreakpoint(breakpointSizes.large),
  xlarge: maxMediaBreakpoint(breakpointSizes.xlarge),
} as const;

export const containerBreakPoints = {
  tablet: containerMediaBreakpoint(breakpointSizes.tablet),
  desktop: containerMediaBreakpoint(breakpointSizes.desktop),

  xsmall: containerMediaBreakpoint(breakpointSizes.xsmall),
  small: containerMediaBreakpoint(breakpointSizes.small),
  shmedium: containerMediaBreakpoint(breakpointSizes.shmedium),
  medium: containerMediaBreakpoint(breakpointSizes.medium),
  large: containerMediaBreakpoint(breakpointSizes.large),
  xlarge: containerMediaBreakpoint(breakpointSizes.xlarge),
} as const;

/**
 * Define both light and dark colors for a given CSS property without having to specify a selector or media query.
 *
 * Only works for `light` and `dark` modes. It does not work with any other values such as custom theme names.
 *
 * @example
 *  Calling this:
 * ```ts
 * style({
 *   backgroundColor: mode('blue', 'red')
 * })
 * ```
 * Results in the following CSS:
 * ```css
 * .someClass {
 *   backgroundColor: light-dark(blue, red);
 * }
 * ```
 */
export const lightDark = <
  LC extends ThemeColorOrAnyColor,
  DC extends ThemeColorOrAnyColor,
>(
  lightColor: LC,
  darkColor: DC,
) => `light-dark(${lightColor}, ${darkColor})` as const;
